<template>
    <div class='instructions'>
        <!-- 正面 -->
        <div class="content">
            <div class="contItem user1">
                <div style="font-size: 60px;font-weight: bold;padding-top: 20px;">Hi</div>
                <div style="font-size: 54px">{{ dataInfo.UserName }}</div>
            </div>
            <div class="contItem details2">
                <div class="logo">
                    <img class="img" :src="dataInfo.CompanyTemp?.CompanyLogoImage" alt="">
                </div>
                <div class="decoding">{{ dataInfo.CompanyTemp?.ContentTitle }}</div>
                <div class="text">
                    {{ dataInfo.CompanyTemp?.Contents }}
                </div>
            </div>
            <div class="contItem take3">
                <div class="take3-main">
                    <div class="take3-left" style="text-align: center;">
                        <div class="title">
                            肠道益生菌5R
                        </div>
                        <div class="title">
                            个性化配方核心成分
                        </div>
                        <img class="img" src="../../assets/bottle.png" alt="">
                    </div>
                    <div class="take3-rigth">
                        <!-- v-for="(item, index) in take3List" -->
                        <div class="take3-rigth-list">
                            <div>
                                <p>
                                    1、去除（Remove）:
                                <div style="padding-left: 32px;">姜黄、大蒜提取物</div>

                                </p>
                                <p>
                                    2、补充（Replace）:
                                    <div style="padding-left: 32px;">甜菜碱、蛋白酶</div>
                                </p>
                                <p>
                                    3、再接种（Reinoculate）:
                                    <div style="padding-left: 32px;">植物乳植杆菌Lp-G18、长双歧杆菌长亚种BL-G301、嗜酸乳杆菌LA-G80、动物双歧杆菌乳亚种BL-G101、 干酪乳酪杆菌LC-G11、副干酪乳酪杆菌LPc-G110、 罗伊氏粘液乳杆菌LR-G100、植物乳植杆菌ZJUF T34、两歧双歧杆菌BB-G90、瑞士乳杆菌LH-G51、发酵粘液乳杆菌LF-G89、鼠李糖乳酪杆菌Lr-G14、短双歧杆菌BB-G95、益生元</div>
                                </p>
                                <p>
                                    4、修复（Repair）:
                                    <div style="padding-left: 32px;"> 谷氨酰胺、牛磺酸、烟酸 、硝酸硫胺素、D-生物素、胆钙化醇 、盐酸吡哆醇、综合维生素</div>
                                </p>
                                <p>
                                    5、重建平衡（Rebalance）: 
                                    <div style="padding-left: 32px;">
                                        朝鲜蓟提取物、针叶樱桃提取物
                                    </div>
                                </p>
                            </div>
                            <!-- <div class="dot"></div> -->
                            <!-- <div class="text">{{ item }}</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="contItem take4">
                <div class="footer">
                    <p style="font-size: 20px;font-weight: bold;">温馨提示：</p>
                    <p> 早晚250--300毫升温水冲服，可根据自己口味增加新鲜柠檬，薄荷;</p>
                    <p> 如需在饭前服用的保持空腹时间越长，吸收效果越好;</p>
                </div>
            </div>
        </div>
        <!-- 背面 -->
        <div class="content">
            <div class="contItem head5">
                <div class="headTitle" style="padding-top: 30px;">
                    <div class="title">{{ title[0] }}</div>
                    <div class="subtitle">{{ title[1] }}</div>
                </div>
                <div class="statement">
                    <div>
                        <li style="line-height: 30px;">本产品为肠道益生菌5R定制，不用于治疗、诊断、预防或治愈疾病。</li>
                        <li style="line-height: 30px;">本产品采用密封包装，收到请尽快服用 </li>
                    </div>
                </div>
            </div>
            <div class="contItem food6">
                <img class="img" :src="dataInfo.CompositionFormula?.ProjectCompositionFormulaImage" alt="">
            </div>
            <div class="contItem certificate7">
                <img style="width: 440px; height: 420px;" class="img" :src="dataInfo.CompanyTemp?.FormulaCert">
            </div>
            <div class="contItem comtentlife8">
                <div class="text-content">
                    <div class="text-list">

                        <div class="text-item">【产品名称】 肠道益生菌5R定制</div>

                        <div>【贮存条件】 请置于阴凉干燥处保存</div>
                    </div>
                    <div class="text-list">
                        <template v-if="dataInfo.CompanyName == '薇凯'">
                            <div class="text-item">【生产日期】 {{ dates }}</div>
                        </template>
                        <template v-else>
                            <div class="text-item">【生产日期】 见外包装盒底部</div>
                        </template>
                        <div class="text-item" style="padding-right: 95px;">【保质期】 {{ dataInfo.CompanyTemp?.QualityGuarantee
                        }}</div>
                    </div>
                </div>
                <div class="imgages">
                    <img class="img" :src="dataInfo.CompanyTemp?.FootImage" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { instructionsPdfOffline } from '@/api/index'

export default {
    name: 'instructionsPdfOffline',
    components: {

    },
    data() {
        return {
            dataInfo: {},
            takeList: [],
            title: [],
            dates: '',
            methodsList: [],
            methods3: '',
            takeMethods41: '55',
            takeMethods42: '66',
            take3List: [
                '1、去除（Remove）:姜黄、大蒜提取物',
                '2、补充（Replace）: 甜菜碱、蛋白酶',
                '3、再接种（Reinoculate）:植物乳植杆菌Lp-G18、长双歧杆菌长亚种BL-G301、嗜酸乳杆菌LA-G80、动物双歧杆菌乳亚种BL-G101、干酪乳酪杆菌LC-G11、副干酪乳酪杆菌LPc-G110、罗伊氏粘液乳杆菌LR-G100、植物乳植杆菌ZJUF T34、两歧双歧杆菌BB-G90、瑞士乳杆菌LH-G51、发酵粘液乳杆菌LF-G89、鼠李糖乳酪杆菌Lr-G14、短双歧杆菌BB-G95、益生元',
                '4、修复（Repair）:谷氨酰胺、牛磺酸、烟酸 、硝酸硫胺素、D-生物素、胆钙化醇 、盐酸吡哆醇、综合维生素',
                '5、重建平衡（Rebalance）:朝鲜蓟提取物、针叶樱桃提取物 '
            ]
            // take3List: [
            //     'DDS牌嗜酸乳杆菌',
            //     '乳酸岐杆菌',
            //     '格式乳杆菌BNR17',
            //     '嗜酸乳杆菌',
            //     '长双岐植物乳杆菌',
            //     '鼠李糖乳杆菌',
            //     '干酪乳杆菌',
            //     'LRC罗伊氏乳杆菌',
            //     '副干酪乳杆菌',
            //     '两岐双岐杆菌'
            // ]
        }
    },
    props: {

    },
    created() {
        this.getinstructionsData()
    },
    methods: {
        async getinstructionsData() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            this.dates = `${year}年${month}月${day}日`
            const id = this.$query('id')
            const parmas = {
                id
            }
            const { data } = await instructionsPdfOffline(parmas)
            this.dataInfo = data
            this.title = data.CompanyTemp.CompanyTitle.split(',')
            this.methodsList = this.dataInfo.MethodColorList.filter((v, i) => i != 0)
        },
        //   处理服用方法
        handletakeList(data) {
            data.MethodColorList.map((v, i) => {
                switch (i) {
                    case 0:
                        this.takeList.push({
                            takeMethod: data.Meth1,
                            imageUrl: v.ColorImage
                        })
                        break
                    case 1:
                        this.takeList.push({
                            takeMethod: data.Meth2,
                            imageUrl: v.ColorImage
                        })
                        break
                    case 2:
                        this.takeList.push({
                            takeMethod: data.Meth3,
                            imageUrl: v.ColorImage
                        })
                        break
                    case 3:
                        this.takeList.push({
                            takeMethod: data.Meth4,
                            imageUrl: v.ColorImage
                        })
                        break
                    default:
                        this.takeList = []
                }
            })
        }
    },
}
</script>
<style lang='scss' scoped>
.instructions {
    width: 100%;
    height: 100%;

    .content {
        width: 550px;
        height: 1694px;
        padding: 0 40px;

        .contItem {
            width: 100%;
            height: 420px;
            padding: 30px 0 15px 0;
        }

        .user1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // padding-bottom: 120px;
            font-size: 40px;

            .dajuya-img {
                // width: 459px;
                // height: 447px;
                padding-top: 140px;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .details2 {
            .logo {
                width: 100px;
                height: 40px;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }

            .delHead {
                font-size: 28px;
            }

            .decoding {
                padding-top: 40px;
                font-size: 18px;
            }

            .text {
                line-height: 30px;
                text-indent: 2em;
                padding: 25px 0;
                border-bottom: 1px solid #000;
            }
        }

        .take3 {
            border-bottom: 1px dashed #cacacb;

            .take3-main {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .take3-left {
                    padding-top: 10px;

                    .title {
                        width: 140px;
                        font-size: 12px;
                        font-weight: bold;
                        padding-right: 20px;
                    }

                    .img {
                        width: 79.2px;
                        height: 189px;
                        margin-top: 30px;
                        margin-left: 20px;
                    }
                }

                .take3-rigth {
                    // flex: 1;
                    // margin-left: 50px;
                    margin-top: 20px;

                    .take3-rigth-list {
                        display: flex;
                        align-items: center;

                        // padding-right: 20px;
                        p {
                            letter-spacing: 1.5px;
                            line-height: 14px;
                            padding: 3px;
                        }
                    }
                }
            }
        }

        .take4 {
            display: flex;
            // justify-content: center;
            align-items: center;

            .footer {
                padding-top: 50px;

                p {
                    line-height: 30px;
                }
            }
        }

        .head5 {
            .logo {
                width: 100px;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }

            .headTitle {
                font-size: 22px;
                margin-top: 35px;
                font-weight: bold;

                .subtitle {
                    font-size: 20px;
                    margin: 5px 0 10px 0;
                }
            }

            .statement {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                height: 150px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding: 10px 0;
            }
        }

        .food6 {
            img {
                width: 480px;
                height: 380px;
            }
        }

        .certificate7 {
            padding: 60px 0;
        }

        .comtentlife8 {
            .text-content {
                .text-list {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;

                    .text-item {
                        line-height: 24px;
                    }
                }
            }

            .imgages {
                padding-top: 80px;
            }

        }
    }
}
</style>
